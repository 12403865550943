<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Find</h4>
    <p>Find a specific fingerprint by entering a message</p>
    <CButton @click="loadPath('sms-fingerprint/find')" color="info" class="card-box-button">
      Find
    </CButton>
  </div>

  <div class="card-box">
    <h4>Explain</h4>
    <p>Explain the steps a message goes through during fingerprinting</p>
    <CButton @click="loadPath('sms-fingerprint/explain')" color="info" class="card-box-button">
      Explain
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'SMS-Fingerprint',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
